@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Text:wght@400;500;700&display=swap');

body,a,h1,h2,h3,h4,h5,h6,p{
    margin: 0;
    font-family: 'Red Hat Text', sans-serif;
    box-sizing: border-box;
    text-align: center;
}
h1{
    color:white;
    font-size: 24px;
    letter-spacing: 6px;
}
html,body, #root, .App{
    height: 100%;
}
.App{
    display: flex;
    flex-direction: column;
    height: 100%;
    background: rgb(26,24,35);
    background: linear-gradient(180deg, rgba(26,24,35,1) 0%, rgba(48,36,58,1) 100%);
}
section{
    width: 100%;
    height: 100%;
    flex:1;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    position: relative;
    background-image:url("./../public/bg-stars.svg");
    h1{
        margin-top: 15px;
    }
    .countdown-container{
        display: flex;
        gap:32px;
        margin-top: 105px;
        .date-text{
            color:#8385AB;
            font-size: 16px;
            letter-spacing: 4px;
            font-weight: bold;
            margin-top: 25px;
        }
        .countdown-item-container{
            .countdown-item{
                font-weight: bold;
                font-size: 80px;
                display: flex;
                position: relative;
                flex-direction: column;
                width: 100%;
                min-width: 150px;
                // height: 140px;
                
                .half-circle-vertical {
                    width: 6px;
                    height: 12px;
                    background-color: #181B22;
                    position: absolute;
                    z-index: 3;
                    &.--left{
                        top:45%;
                        border-top-left-radius: 0;
                        border-bottom-left-radius: 0;
                        border-top-right-radius: 50px;
                        border-bottom-right-radius: 50px;
                        z-index: 1000;
                    }
                    &.--right{
                        top:45%;
                        right: 0;
                        border-top-left-radius: 50px;
                        border-bottom-left-radius: 50px;
                        border-top-right-radius: 0;
                        border-bottom-right-radius: 0;
                        z-index: 1000;
                    }
                }
                .countdown-item-top{
                    background-color: #2C2C44;
                    color:#FE5C83;
                    height: 68px;
                    z-index: 2;
                    
                    border-radius: 8px 8px 0px 0px;
                    // position: relative;
                    // min-height: 70px;
                    position: relative;
                    display: flex;
                    align-items: flex-end;
                    justify-content: center;
                    &.--absolute{
                        width: 100%;
                        position: absolute !important;
                        z-index: 3;
                        // border-top: 0.5px solid black;
                        // border-left: 0.5px solid black;
                        // border-right: 0.5px solid black;
                    }
                    .shadow{
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        background-color: #2C2C44;
                        left: 0;
                        z-index: 10000;
                        opacity: 0.2;
                    }
                    // p{
                    //     margin-top: 9%;
                    //     overflow: hidden; /* Ensure content doesn't overflow when height is reduced */
                    //     white-space: nowrap; /* Prevent text from wrapping */
                    //     transition: height 2s; /* Ensure smooth transition */
                    // //   position: absolute;
                    // //   clip-path: polygon(0 0, 100% 0, 100% 50%, 0 50%);  
                    // }
                    img{
                        position: relative;
                    }
                    
                }
                .countdown-item-bottom{
                    // position: absolute;
                    background-color: #34364F;
                    // bottom: 0;
                    height: 68px;
                    // // width: 100%;
                    z-index: 1;
                    color: #FE5C83;
                    border-radius: 0px 0px 8px 8px;
                    // position: relative;
                    display: flex;
                    align-items: flex-start;
                    justify-content: center;

                    &.--bordered{
                        -webkit-box-shadow: 0px 18px 4px -8px rgba(23,25,37,0.93);
                        -moz-box-shadow: 0px 18px 4px -8px rgba(23,25,37,0.93);
                        box-shadow: 0px 18px 4px -8px rgba(23,25,37,0.93);
                    }
                    &.--absolute{
                        width: 100%;
                        position: absolute !important;
                        bottom: 0;
                        z-index: 1;
                        // border-bottom: 0.5px solid black;
                        // border-left: 0.5px solid black;
                        // border-right: 0.5px solid black;
                        display: none;
                    }
                    img{
                            position: relative;
                        //   clip-path: polygon(0 50%, 100% 50%, 100% 100%, 0 100%);  
                    } 
                }
            }
        }
        }
    }
footer{
    width: 100%;
    height: 197px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url("./../public/pattern-hills.svg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    gap:30px;
    a{
        cursor: pointer;
        svg{
            path{
                transition: 0.3s ease;
                fill:#8385AB;
                
            }
        }
        &:hover svg path{
            fill:#F66083;
            transition: 0.3s ease;
        }
    }
}

.fadeBottom-anim {
    -webkit-animation: scale-bottom-ver-top 0.3s  linear forwards ;
	animation: scale-bottom-ver-top 0.3s  linear forwards ;
    display: flex !important;
  }

.fadeTop-anim {
    -webkit-animation: scale-up-ver-bottom 0.3s linear reverse forwards;
	animation: scale-up-ver-bottom 0.3s linear reverse forwards;
}

@-webkit-keyframes scale-up-ver-bottom {
    0% {
      -webkit-transform: scaleY(0.0);
              transform: scaleY(0.0);
      -webkit-transform-origin: 0% 100%;
              transform-origin: 0% 100%;
        background-color: #3a3c5b;
    }
    100% {
      -webkit-transform: scaleY(1);
              transform: scaleY(1);
      -webkit-transform-origin: 0% 100%;
              transform-origin: 0% 100%;
        background-color: #2C2C44;
    }
  }
  @keyframes scale-up-ver-bottom {
    0% {
      -webkit-transform: scaleY(0.0);
              transform: scaleY(0.0);
      -webkit-transform-origin: 0% 100%;
              transform-origin: 0% 100%;
        background-color: #3a3c5b;
    }
    100% {
      -webkit-transform: scaleY(1);
              transform: scaleY(1);
      -webkit-transform-origin: 0% 100%;
              transform-origin: 0% 100%;
        background-color: #2C2C44;
    }
  }

  @-webkit-keyframes scale-bottom-ver-top {
    0% {
      -webkit-transform: scaleY(0.0);
              transform: scaleY(0.0);
      -webkit-transform-origin: 100% 0%;
              transform-origin: 100% 0%;
        background-color: #3a3c5b;
    }
    100% {
      -webkit-transform: scaleY(1);
              transform: scaleY(1);
      -webkit-transform-origin: 100% 0%;
              transform-origin: 100% 0%;
        background-color: #34364F;
    }
  }
  @keyframes scale-bottom-ver-top {
    0% {
      -webkit-transform: scaleY(0.0);
              transform: scaleY(0.0);
      -webkit-transform-origin: 100% 0%;
              transform-origin: 100% 0%;
        background-color: #3a3c5b;
    }
    100% {
      -webkit-transform: scaleY(1);
              transform: scaleY(1);
      -webkit-transform-origin: 100% 0%;
              transform-origin: 100% 0%;
        background-color: #34364F;
    }
  }

  

@media screen and (max-width: 710px) {
    .half-circle-vertical {
        &.--left{
            top:20% !important;
        }
        &.--right{
            top:20% !important;
        }
    }
    .countdown-item{
        font-size: 36px !important;
        min-width: 70px !important;
        height: auto !important;
    }
    .countdown-item-top{
        height: 34px !important;
        img{
            width: 15px !important;
            max-height: 10px !important;
        }
    }
    .countdown-item-bottom{
        height: 34px !important;
        img{
            width: 15px !important;
            max-height: 10px !important;
        }
    }
    .date-text{
        font-size: 7px !important;
        letter-spacing: 3px !important;
        margin-top: 15px !important;
    }
  }

@media screen and (max-width: 390px) {
    .countdown-container{
        gap: 12px !important;
    }
}